import React from 'react';
import styled from 'styled-components';
import { Section, TextBox } from './layout';

const FooterTop = styled(Section)`
  @media (min-width: 600px) {
    display: flex;
    gap: 30px;
  }
`

const Legal = styled(Section).attrs({ theme: 'accent' })`
  text-align: center;
  font-size: 0.8rem;
`

const Footer = () => {
  const currentYear = new Date().getFullYear()
  return (
    <footer>
      <FooterTop theme='alt'>
        <TextBox>
          <h2>Contact</h2>
          <p><a href="tel:7406072128">740-607-2128</a></p>
          <p><a href="mailto:kathleenwarne@att.net">kathleenwarne&#64;att.net</a></p>
          <p>Philo, Ohio</p>
        </TextBox>
        <TextBox>
          <h2 id="policies">Policies</h2>
          <ul>
            <li>A deposit is required to start work.</li>
            <li id="estimate">An estimate is subject to change if the project
              is more complex than initally known, ex. rotting or broken frame, etc.
            </li>
            <li id="deliveryFee">Delivery is available by request for an additional fee based on mileage.
            </li>
            <li id="storage">If pickup is extensively delayed past the agreed date, a storage fee will be added to your final bill.
            </li>
            <li>Payment accepted via cash or check.</li>
          </ul>
        </TextBox>
      </FooterTop>
      <Legal>
        <TextBox>
          <p>&copy; 2022{currentYear === 2022 ? '' : ` - ${currentYear}`}. Website by <a href="https://www.arielkaplan.com" target="_blank" rel="noopener noreferrer">Ariel Kaplan</a></p>
        </TextBox>
      </Legal>
    </footer>
  )
}

export default Footer
