import React from 'react';
import Gallery from './Gallery';
import {Section, TextBox} from './layout';

const Main = () => {
  return (
    <main>
      <Section>
        <TextBox>
          <h2>Custom Upholstery</h2>
          <p>Specializing in boat seats, furniture, and car and truck seats,
            I use quality upholstery-grade material and marine-grade vinyls.
          </p>
          <p>Have something more unusual in mind? Send me a message.</p>
        </TextBox>
      </Section>
      <Section>
        <TextBox>
          <Gallery />
        </TextBox>
      </Section>
      <Section>
        <TextBox>
          <h2>Process</h2>
          <h3>1. Estimate</h3>
          <p>Send me details about your project and some photos,
            and I will provide an <a href="#estimate">estimate*</a>.</p>
          <p>If you agree to the estimate, we schedule a time
            for you to drop off your project.
          </p>
          <h3>2. Work</h3>
          <p>You drop off your project and a deposit, and we schedule a time for pick-up or <a href="#deliveryFee">delivery*</a>.</p>
          <p>If there are complications to the project that
            were unknown at the time of estimate, I will contact you for an adjustment and approval.
          </p>
          <h3>3. Completion</h3>
          <p>It's all done!</p>
          <p>Balance is due on pickup or delivery.</p>
          <p><i>If your requirements of a completion date <a href="#storage">change*</a>, please
            reschedule ASAP.</i></p>
        </TextBox>
      </Section>
    </main>
  )
}

export default Main;
