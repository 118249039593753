import styled from 'styled-components';

export const TextBox = styled.div`
  max-width: 900px;
  margin-left: auto;
  margin-right: auto;
`

export const Section = styled.section<{ theme?: 'alt' | 'accent' }>`
  background-color: var(--bg);
  color: var(--fg);
  ${({ theme }) => theme === 'alt' && `
    background-color: var(--bg-alt);
    color: var(--fg-alt);
  `}
  ${({ theme }) => theme === 'accent' && `
    background-color: var(--bg-accent);
    color: var(--fg-accent);
  `}
  padding: 1.5em 1em;
  @media (min-width: 600px) {
    padding: 2em 3em;
  }
`
