import React from 'react';
import styled from 'styled-components';
import imgBarstools from '../images/barstools.jpg';
import imgRecliner from '../images/recliner.jpg';
import imgRuffledChair from '../images/ruffledchair.jpg';
import imgSideChair from '../images/sidechair.jpg';
import imgSkirtChair from '../images/skirtchair.jpg';
import imgTruckSeat from '../images/truckseat.jpg';
import { Navigation, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

// Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';

const gallery = [
  {
    url: imgBarstools,
    caption: 'Matching armless bar stools'
  },
  {
    url: imgTruckSeat,
    caption: 'Vinyl quilted truck seat'
  },
  {
    url: imgRecliner,
    caption: 'Tailored Recliner'
  },
  {
    url: imgRuffledChair,
    caption: 'Tufted and ruffled side chair'
  },
  {
    url: imgSideChair,
    caption: 'Modern side chair'
  },
  {
    url: imgSkirtChair,
    caption: 'Traditional side chair with skirt'
  }
];

const Container = styled.div`
  .swiper-button-prev,
  .swiper-button-next {
    transform: opacity 0.2s ease-in-out;
    min-width: 40px;
    color: var(--fg-accent);
    filter: drop-shadow(1px 1px 5px var(--bg-accent));
    opacity: 0.8;
    &:hover {
      opacity: 1;
    }
    &::after {
      font-size: 36px;
    }
  }
`

const AspectWrapper = styled.div`
  position: relative;
  width: 100%;
  padding-top: 120%;
`

const Image = styled.div<{ url: string }>`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: var(--accent) url(${({ url }) => url}) center/cover no-repeat;
`

const Gallery = () => {
  return (
    <Container>
      <Swiper
        spaceBetween={20}
        slidesPerView={1}
        grabCursor={true}
        navigation={true}
        loop={true}
        modules={[Navigation, A11y]}
        breakpoints={{
          320: {
            slidesPerView: 2,
          },
          600: {
            slidesPerView: 3,
          },
          800: {
            slidesPerView: 4,
          }
        }}
      >
        {gallery.map(item => {
          return (
            <SwiperSlide key={item.url}>
              <AspectWrapper>
                <Image 
                  url={item.url} 
                  role='img' 
                  aria-label={item.caption} 
                />
              </AspectWrapper>
            </SwiperSlide>
          )
        })}
      </Swiper>
    </Container>
  );
};

export default Gallery;
