import React from 'react';
import styled from 'styled-components';
import Footer from './components/Footer';
import { Section, TextBox } from './components/layout';
import Main from './components/Main';

const Hero = styled(Section).attrs({ theme: 'accent'})`
  border-top: 10px solid var(--accent);
  border-bottom: 3px solid var(--accent);
  padding: 3em 1em;
  text-align: center;
  @media (min-width: 600px) {
    padding: 4em 3em;
  }
`

const Heading = styled.h1`
  font-size: 32px;
  @media (min-width: 600px) {
    font-size: 40px;
  }
`

function App() {
  return (
    <div>
      <Hero>
        <TextBox>
          <Heading>Kathy's Creations</Heading>
          <p>Custom Upholstery by Kathy Warne</p>
          <p>Philo, Ohio</p>
        </TextBox>
      </Hero>
      <Main />
      <Footer />
    </div>
  );
}

export default App;
